<template>
  <div class="app-container">
    <el-row :gutter="4" type="flex" justify="center">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Agregar Sucursal</span></strong>
          </div>
          <div class="text item">
            <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form @keypress.enter.native.prevent="handlerCreate" :model="form" label-position="top" class="size-form-item" label-width="120px">
                <el-row type="flex" justify="center">
                  <el-col :span="24">
                    <el-form-item label="Nombre">
                      <ValidationProvider name="Nombre de Surcursal" rules="stringMax255|required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.name"></el-input>
                        <span class="input-span--error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="KeyName">
                      <ValidationProvider name="KeyName" rules="regexLocationKey|uniqueLocationKey|required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.key"></el-input>
                        <span class="input-span--error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row justify="center">
                  <el-col style="text-align: left;"  :span="24">
                    <span>Metadata</span>
                  </el-col>
                  <el-col :span="24" align="middle">
                    <el-row :gutter="10" justify="center">
                      <template v-if="inputList.length !== 0 || inputList !== null">
                        <div v-for="(input, index) in inputList" :key="index">
                          <el-col :span="12">
                            <ValidationProvider name="Key" rules="regexObjectKey|required" v-slot="{ errors }">
                              <el-form-item :key="`inputProp${index}`" style="margin-bottom: 1rem; line-height: 1.2em;">
                                <el-input v-model="input.key" size="mini" placeholder="Nombre"></el-input>
                                <span class="input-span-object--error">{{ errors[0] }}</span>
                              </el-form-item>
                            </ValidationProvider>
                        </el-col>
                        <el-col style="display: inline-block;" :span="12">
                          <el-row type="flex" justify="center" style="margin: 0;">
                            <el-col :span="20">
                              <ValidationProvider name="Valor" rules="required" v-slot="{ errors }">
                                <el-form-item style="margin-bottom: 1rem;">
                                  <el-input v-model="input.value" size="mini" placeholder="Valor"></el-input>
                                  <span class="input-span-object--error">{{ errors[0] }}</span>
                                </el-form-item>
                              </ValidationProvider>
                            </el-col>
                            <el-col style="display: flex; align-items: center;" :span="4">
                              <el-button style="margin: auto;" @click="handleRemoveInput(index)" type="danger" icon="el-icon-delete" circle plain size="mini"></el-button>
                            </el-col>
                          </el-row>
                        </el-col>
                        </div>
                      </template>
                    </el-row>
                  </el-col>
                  <el-col style="text-align: center;" align="middle" :span="24">
                    <el-form-item>
                      <el-tooltip class="item" effect="dark" content="Puede agregar datos extras para detallar mayor información de la sucursal" placement="bottom">
                        <el-button
                          @click.stop.prevent="handleAddInput"
                          type="warning"
                          size="mini"
                          icon="el-icon-circle-plus-outline"
                          >
                          Agregar Propiedad
                        </el-button>
                      </el-tooltip>
                  </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="center" :gutter="20">
                <el-col :span="12" align="right">
                  <el-form-item>
                    <el-button
                      @click.stop.prevent="handlerCreate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Agregar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="12" align="left">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              </el-form>
            </ValidationObserver>
        </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { createLocation } from '@/api/bioLocations.js'
export default {
  name: 'createLocation',
  data () {
    return {
      form: {
        key: '',
        name: '',
        metadata: {},
        keyBioCompany: 'ccnatacion'
      },
      inputList: [{
        key: '',
        value: ''
      }],
      inputsTransformed: {}
    }
  },
  computed: {
    metadataStatus () {
      return Object.keys(this.form.metadata).length
    }
  },
  methods: {
    handleAddInput () {
      this.inputList.push({
        key: '',
        value: ''
      })
      console.log(this.inputList)
    },
    handleRemoveInput (index) {
      this.inputList.splice(index, 1)
      console.log(this.inputList)
    },
    transformInputs () {
      this.inputList.forEach(input => {
        if (input.key !== '' && input.value !== '') {
          this.inputsTransformed[`${input.key}`] = input.value
        }
      })
      this.form.metadata = this.inputsTransformed
    },
    async handlerCreate (info) {
      this.transformInputs()
      console.log(this.form)
      if (this.form.key !== '' && this.form.name !== '' && Object.keys(this.form.metadata).length > 0) {
        try {
          const response = await createLocation(this.form)
          console.log(response)
          if (response.success) {
            this.$message({
              showClose: true,
              message: '¡Se creó correctamente!',
              type: 'success'
            })
            this.$router.push('/locations')
          } else {
            console.log(response)
          }
        } catch (err) {
          err.response.data.messages.forEach(message => {
            this.$message.error(`${message}`)
          })
        }
      } else {
        this.$message.error('Metadata debe contener mínimo un campo')
      }
    },
    async handlerCancel () {
      this.$router.push('/locations')
    }
  }
}
</script>

<style scoped>
.input-span--error {
  color: #F56C6C;
  font-size: 0.8rem;
}
.input-span-object--error {
  color: #F56C6C;
  font-size: 0.8em;
  line-height: 1em;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 2.4rem;
}
</style>
